<template>
  <div>
    <div class="bill-title">借还款单</div>
    <el-form
      :model="form"
      status-icon
      :rules="rules"
      ref="form"
      label-width="80px"
    >
      <el-row>
        <el-col :span="6">
          <el-form-item label="单据编号" prop="billCode">
            <el-input v-model="form.billCode" placeholder="单据编号"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="单据日期" prop="billDate">
            <el-date-picker
              style="width: 150px"
              @change="getBillCode(8)"
              v-model="form.billDate"
              placeholder="单据日期"
              :clearable="false"
              :editable="false"
            ></el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="单据类型" prop="billType">
            <el-radio-group v-model.number="form.billType">
              <el-radio :label="1" border>借款</el-radio>
              <el-radio :label="2" border>还款</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="6">
          <el-form-item label="项目" prop="projectId">
            <project-select
              v-model="form.projectId"
              :canAdd="true"
              :state="[1, 2]"
              :addId="addProjectId"
              @addClick="
                () => {
                  this.projectItem = {};
                }
              "
            ></project-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="员工" prop="employeeId">
            <base-select
              info="Employee"
              v-model="form.employeeId"
              :canAdd="true"
              @addClick="
                () => {
                  this.employeeItem = {};
                }
              "
            ></base-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="金额" prop="amount">
            <el-popover ref="amount" placement="top-start" trigger="focus">
              <span style="color: teal">{{ form.amount | money }}</span>
            </el-popover>
            <el-input
              v-popover:amount
              type="number"
              v-model="form.amount"
              placeholder="金额"
              clearable
              @mousewheel.native.prevent
            >
              <template slot="append">元</template>
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-form-item label="附件">
        <BillAttach :attachId="form.attachId"></BillAttach>
      </el-form-item>
      <el-form-item label="备注">
        <el-input
          v-model="form.remark"
          placeholder="备注"
          type="textarea"
          :rows="2"
          clearable
        ></el-input>
      </el-form-item>

      <el-form-item>
        <span v-if="canntEditMsg" style="color: red"
          >{{ canntEditMsg }} 单据不可编辑</span
        >
        <el-button-group v-else>
          <el-button type="info" @click="save(false)">保存关闭</el-button>
          <el-button type="success" @click="save(true)">保存新增</el-button>
          <el-button type="warning" @click="cancel">取消关闭</el-button>
          <el-button type="danger" @click="deleteBill" v-if="form.id&&hasPermission('DeleteBill')"
            >删除单据</el-button
          >
        </el-button-group>
      </el-form-item>
      <el-form-item>
        <operator-box :item="form"></operator-box>
      </el-form-item>
    </el-form>
    <div>
      <add-project
        :item="projectItem"
        @success="
          (id) => {
            this.form.projectId = id;
          }
        "
      ></add-project>
      <add-employee
        :item="employeeItem"
        @success="
          (id) => {
            this.form.employeeId = id;
          }
        "
      ></add-employee>
    </div>
  </div>
</template>

<script>
import billcode from "@/common/billcodeMixins.js";
import BaseSelect from "@/components/BaseSelect.vue";
import ProjectSelect from "@/components/ProjectSelect.vue";

import AddProject from "@/views/project/Item.vue";
import AddEmployee from "@/views/employee/Item";
import OperatorBox from "@/components/OperatorBox";
import guid from "@/common/guid.js";
import BillAttach from "@/components/BillAttach";
export default {
  mixins: [billcode],
  components: {
    BaseSelect,
    ProjectSelect,
    AddProject,
    AddEmployee,
    OperatorBox,
    BillAttach,
  },
  data() {
    return {
      routeTab: "借还款单",
      form: {},
      rules: {
        billCode: [this.$g.required],
        billDate: [this.$g.required],
        billType: [this.$g.required],
        projectId: [this.$g.required],
        employeeId: [this.$g.required],
        amount: [this.$g.required, this.$g.moreThanZero],
      },

      addProjectId: null, //不可用项目Id(编辑时要显示)
      stopList: [], //停用基础信息
      canntEditMsg: "", //不可编辑的提示信息

      projectItem: {},
      employeeItem: {},
    };
  },
  mounted() {
    this.init(this.$route.query.id);
  },
  beforeDestroy() {
    this.stopList.forEach((item) => {
      this.$store.commit("removeBaseInfo", {
        info: item.info,
        id: item.baseInfo.id,
      });
    });
  },
  methods: {
    //初始化
    init(id) {
      this.addProjectId = null;
      this.stopList = [];
      this.canntEditMsg = "";
      if (id) {
        this.editInit(id);
      } else {
        this.addInit();
      }
    },
    //新增初始化
    addInit() {
      this.form = {
        billCode: "",
        billDate: new Date(),
        billType: 1,
        projectId: "",
        employeeId: "",
        amount: null,
        remark: "",
        attachId: guid(),
      };
      this.getBillCode(8);
      this.$nextTick(() => {
        this.$refs["form"].clearValidate();
      });
    },
    //编辑初始化
    editInit(id) {
      this.$get("Borrower/Detail", {
        id,
      }).then((r) => {
        this.routeTab = r.bill.billcode;
        this.form = { ...r.bill };

        this.addProjectId = r.addProjectId;
        this.stopList = r.stopList;
        this.canntEditMsg = r.canntEditMsg;
        this.stopList.forEach((item) => {
          this.$store.commit("addBaseInfo", {
            info: item.info,
            item: item.baseInfo,
          });
        });
      });
    },
    //保存
    save(isContinue) {
      this.$refs["form"].validate((valid) => {
        if (!valid) {
          return;
        }
        let bill = { ...this.form };
        if (bill.amount <= 0) {
          this.$message.error("金额必须大于0");
          return;
        }
        let loading = this.$loading({
          lock: true,
          text: "保存中...",
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.7)",
        });
        let url = "";
        if (this.form.id) {
          url = "Borrower/Edit";
        } else {
          url = "Borrower/Add";
        }
        this.$post(url, bill)
          .then((r) => {
            this.$message.success("操作成功！");
            if (isContinue) {
              this.$tabs.close({
                to: {
                  name: "BorrowerItem",
                  query: {},
                },
                refresh: true,
              });
            } else {
              this.$tabs.close();
            }
          })
          .finally(() => {
            loading.close();
          });
      });
    },
    //删除单据
    deleteBill() {
      this.$confirm("将删除此借还款单, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let loading = this.$loading({
            lock: true,
            text: "删除中...",
            spinner: "el-icon-loading",
            background: "rgba(0, 0, 0, 0.7)",
          });
          this.$post("Borrower/Delete?id=" + this.form.id)
            .then((r) => {
              this.$message.success("操作成功！");
              this.$g.deleteAttach(this.form.attachId);
              this.$tabs.close();
            })
            .finally(() => {
              loading.close();
            });
        })
        .catch(() => {});
    },
    cancel() {
      if (!this.form.id) {
        this.$g.deleteAttach(this.form.attachId);
      }
      this.$tabs.close();
    },
  },
};
</script>

<style>
</style>