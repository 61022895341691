var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"bill-title"},[_vm._v("借还款单")]),_c('el-form',{ref:"form",attrs:{"model":_vm.form,"status-icon":"","rules":_vm.rules,"label-width":"80px"}},[_c('el-row',[_c('el-col',{attrs:{"span":6}},[_c('el-form-item',{attrs:{"label":"单据编号","prop":"billCode"}},[_c('el-input',{attrs:{"placeholder":"单据编号"},model:{value:(_vm.form.billCode),callback:function ($$v) {_vm.$set(_vm.form, "billCode", $$v)},expression:"form.billCode"}})],1)],1),_c('el-col',{attrs:{"span":6}},[_c('el-form-item',{attrs:{"label":"单据日期","prop":"billDate"}},[_c('el-date-picker',{staticStyle:{"width":"150px"},attrs:{"placeholder":"单据日期","clearable":false,"editable":false},on:{"change":function($event){return _vm.getBillCode(8)}},model:{value:(_vm.form.billDate),callback:function ($$v) {_vm.$set(_vm.form, "billDate", $$v)},expression:"form.billDate"}})],1)],1),_c('el-col',{attrs:{"span":6}},[_c('el-form-item',{attrs:{"label":"单据类型","prop":"billType"}},[_c('el-radio-group',{model:{value:(_vm.form.billType),callback:function ($$v) {_vm.$set(_vm.form, "billType", _vm._n($$v))},expression:"form.billType"}},[_c('el-radio',{attrs:{"label":1,"border":""}},[_vm._v("借款")]),_c('el-radio',{attrs:{"label":2,"border":""}},[_vm._v("还款")])],1)],1)],1)],1),_c('el-row',[_c('el-col',{attrs:{"span":6}},[_c('el-form-item',{attrs:{"label":"项目","prop":"projectId"}},[_c('project-select',{attrs:{"canAdd":true,"state":[1, 2],"addId":_vm.addProjectId},on:{"addClick":function () {
                this$1.projectItem = {};
              }},model:{value:(_vm.form.projectId),callback:function ($$v) {_vm.$set(_vm.form, "projectId", $$v)},expression:"form.projectId"}})],1)],1),_c('el-col',{attrs:{"span":6}},[_c('el-form-item',{attrs:{"label":"员工","prop":"employeeId"}},[_c('base-select',{attrs:{"info":"Employee","canAdd":true},on:{"addClick":function () {
                this$1.employeeItem = {};
              }},model:{value:(_vm.form.employeeId),callback:function ($$v) {_vm.$set(_vm.form, "employeeId", $$v)},expression:"form.employeeId"}})],1)],1),_c('el-col',{attrs:{"span":6}},[_c('el-form-item',{attrs:{"label":"金额","prop":"amount"}},[_c('el-popover',{ref:"amount",attrs:{"placement":"top-start","trigger":"focus"}},[_c('span',{staticStyle:{"color":"teal"}},[_vm._v(_vm._s(_vm._f("money")(_vm.form.amount)))])]),_c('el-input',{directives:[{name:"popover",rawName:"v-popover:amount",arg:"amount"}],attrs:{"type":"number","placeholder":"金额","clearable":""},nativeOn:{"mousewheel":function($event){$event.preventDefault();}},model:{value:(_vm.form.amount),callback:function ($$v) {_vm.$set(_vm.form, "amount", $$v)},expression:"form.amount"}},[_c('template',{slot:"append"},[_vm._v("元")])],2)],1)],1)],1),_c('el-form-item',{attrs:{"label":"附件"}},[_c('BillAttach',{attrs:{"attachId":_vm.form.attachId}})],1),_c('el-form-item',{attrs:{"label":"备注"}},[_c('el-input',{attrs:{"placeholder":"备注","type":"textarea","rows":2,"clearable":""},model:{value:(_vm.form.remark),callback:function ($$v) {_vm.$set(_vm.form, "remark", $$v)},expression:"form.remark"}})],1),_c('el-form-item',[(_vm.canntEditMsg)?_c('span',{staticStyle:{"color":"red"}},[_vm._v(_vm._s(_vm.canntEditMsg)+" 单据不可编辑")]):_c('el-button-group',[_c('el-button',{attrs:{"type":"info"},on:{"click":function($event){return _vm.save(false)}}},[_vm._v("保存关闭")]),_c('el-button',{attrs:{"type":"success"},on:{"click":function($event){return _vm.save(true)}}},[_vm._v("保存新增")]),_c('el-button',{attrs:{"type":"warning"},on:{"click":_vm.cancel}},[_vm._v("取消关闭")]),(_vm.form.id&&_vm.hasPermission('DeleteBill'))?_c('el-button',{attrs:{"type":"danger"},on:{"click":_vm.deleteBill}},[_vm._v("删除单据")]):_vm._e()],1)],1),_c('el-form-item',[_c('operator-box',{attrs:{"item":_vm.form}})],1)],1),_c('div',[_c('add-project',{attrs:{"item":_vm.projectItem},on:{"success":function (id) {
          this$1.form.projectId = id;
        }}}),_c('add-employee',{attrs:{"item":_vm.employeeItem},on:{"success":function (id) {
          this$1.form.employeeId = id;
        }}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }